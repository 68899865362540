import React from 'react';
import { Box } from 'grommet';

import Seo from 'gatsby-plugin-wpgraphql-seo';
import Button from './button';
import PageHeading from './pageHeading';
import { MaxWidthInner } from './maxWidthInner';
import ResponsiveGrid from './responsiveGrid';
import { StyledComponents } from './componentRenderer/ComponentRenderer';
import Cta from './cta';

const EntryList = ({ data, hasNextPage, pageInt, entity, entry, title, intro, cta }) => {
    const TagName = entry;

    const renderPreviousLink = () => {
        let previousLink;

        if (!pageInt) {
            return null;
        }
        if (pageInt <= 2) {
            previousLink = `/${entity}`;
        } else {
            previousLink = `/${entity}/${pageInt}`;
        }

        return <Button to={previousLink} label="Previous" />;
    };

    const renderNextLink = () => {
        if (hasNextPage) {
            return <Button label="Next" to={`/${entity}/${pageInt ? pageInt + 1 : 2}`} reverse />;
        }
        return null;
    };

    const blogPageNumber = pageInt ? ` Page ${pageInt}` : ``;
    return (
        <>
            <Seo title={`${title} ${blogPageNumber}`} />
            <PageHeading title={title || entity} intro={intro} />
            <MaxWidthInner padded>
                <ResponsiveGrid gap="medium">
                    {data && data.nodes.map(post => <TagName key={post.id} item={post} />)}
                </ResponsiveGrid>

                <Box direction="row" justify="between" pad="large">
                    <Box>{renderPreviousLink()}</Box>
                    <Box>{renderNextLink()}</Box>
                </Box>
            </MaxWidthInner>
            {cta && (
                <StyledComponents direction="column" className="component-renderer">
                    <Cta {...cta} />
                </StyledComponents>
            )}
        </>
    );
};

export default EntryList;
