import React, { FC } from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';

const StyledBoxInner = styled(Box)`
    position: ${props => (props.relative ? 'relative' : 'static')};
    max-width: 1040px;
    width: 100%;
    margin: 0 auto;
    padding-left: ${props => props.theme.global.edgeSize.medium};
    padding-right: ${props => props.theme.global.edgeSize.medium};

    @media (min-width: 1200px) {
        max-width: 1120px;
    }
`;

export const MaxWidthInner: FC<any> = ({ children, relative = true, ...props }) => (
    <StyledBoxInner relative={relative} {...props}>
        {children}
    </StyledBoxInner>
);

export default MaxWidthInner;
