import React from 'react';
import { graphql } from 'gatsby';
import ProjectEntry from '../components/projectEntry';
import EntryList from '../components/entryList';

const ProjectsPage = ({ data, pageContext: { hasNextPage, pageInt } }) => (
    <EntryList
        data={data.allWpProject}
        hasNextPage={hasNextPage}
        pageInt={pageInt}
        entity="projects"
        title={data.wp.postTypeExtras.project.title}
        intro={data.wp.postTypeExtras.project.intro}
        entry={ProjectEntry}
    />
);

export default ProjectsPage;

export const query = graphql`
    query GET_PROJECTS($ids: [String]) {
        wp {
            postTypeExtras {
                project {
                    intro
                    title
                }
            }
        }
        allWpProject(filter: { id: { in: $ids } }) {
            nodes {
                ...ProjectEntryFragment
            }
        }
    }
`;
